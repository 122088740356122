<template>
    <b-container fluid class="py-5">
        <b-row>          
            <b-col cols="12" md="2">
                <b-form-group>
                    <b-form-select
                    v-model="paginador.pageSize"
                    :options="pageSizeOptions"
                    @change="getItemsByCategory"
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
                <b-pagination 
                    size="md" 
                    pills 
                    :total-rows="paginador.total" 
                    v-model="paginador.page" 
                    :per-page="1" 
                    @input="changePage" 
                    first-number 
                    last-number>
                </b-pagination>
            </b-col>
        </b-row>
        <div>
            <div v-if="catalogo.length > 0 ">
                <card-item :items="catalogo" @item-info="itemInfo($event)" @add-item="openItemModal($event)" />
            </div>
            <b-alert v-else variant="danger" show>
               Lo sentimos, no se han encontrado resultados para su busqueda.
            </b-alert>
        </div>
        <template v-if="catalogo.length > 0">
            <div class="text-center pt-2">
                <b-pagination 
                    size="md" 
                    pills 
                    :total-rows="paginador.total" 
                    v-model="paginador.page" 
                    :per-page="1" 
                    @input="changePage" 
                    first-number 
                    last-number>
                </b-pagination>
            </div>
        </template>
        <add-item :open-modal="modalAdd" :item="item" @close-modal="closeModal" />
    </b-container>
</template>

<script>
import { mapActions, mapState, mapMutations} from 'vuex'
import AddItem from '@/components/AddItemToCart/AddItem.vue';
import CardItem from '../components/CardItemBase.vue'
export default {
    data(){
        return{
            item: {},
            modalAdd: false,
            catalogo: [],
            paginador: {
                page: 1,
                total: 1,
                pageSize: 18,
                order: 0
            },
            pageSizeOptions: [
                { value: 18, text: '18' },
                { value: 24, text: '24' },
                { value: 48, text: '48' }
            ],
            orderOptions: [
                { value: 0, text: 'Orden inicial' },
                { value: 1, text: 'Productos (A-Z)' },
                { value: 2, text: 'Productos (Z-A)' }
            ]
        }
    },
    components: {
        AddItem,
        CardItem
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('configuration', ['background']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
    },
    watch: {
        '$route.params.categoryCode': function () {
        this.getItemsByCategory()
        }
    },
    created: function () {
        this.getItemsByCategory()
    },
    methods: {
         ...mapActions('configuration', {
          _getCartCount: 'getCartItemsCountByUser',
        }),
        ...mapMutations(['setLoading']),
        async getItemsByCategory(){
            this.catalogo = [];
            let pageOptions = { 
                categoryCode: this.$route.params.categoryCode,
                pageNumber: this.paginador.page,
                pageSize: this.paginador.pageSize,
            };
            this.setLoading(true)
            await this.$conectar.post('/articulos/categoria/get-items-category', pageOptions)
            .then(response =>{
                this.catalogo = response.data.items;
                this.paginador.total = response.data.totalPages;
            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        changePage(page){
            this.paginador.page = page;
            this.getItemsByCategory();
        },
        itemInfo(datos){
            this.$router.push({ name: 'ItemDetail', params:{ referencia: datos.codigo} });
        },
        openItemModal(item){
            this.item.itemName = item.nombre;
            this.item.itemImage = item.imagen;
            this.item.reference = item.codigo;
            this.item.quantity = item.quantity;
            this.modalAdd = true;
        },
        closeModal(){
            this.item = {};
            this.modalAdd = false;
        },
           
        
    }
}
</script>
<style scoped>

</style>