<template>
    <b-row no-gutters> 
        <b-card-group class="col-xl-2 col-lg-3 col-md-4 col-sm-6 " v-for="(item, index) of items" :key="index" >
            <b-card>
                <b-card-img :src="item.imagen" class="pb-0" alt="Image" style="cursor: pointer" @click.prevent="$emit('item-info', item)"></b-card-img>
                <b-card-text>
                    <div class="card-item-name">
                        <b><a class="pt-0 item-name" @click.prevent="$emit('item-info', item)">{{capitalizeFirstLetterOfAllWordsExceptFirst(item.nombre)}}</a></b>
                    </div>
                    <div class="card-item-detail py-2 d-flex flex-column">
                        <span>
                            <b>Referencia: </b> {{ item.codigo }}
                        </span>
                        <span>
                            <b>Código de barras: </b> {{  item.codigo_barras }}
                        </span>
                        <span>
                            <b>Disponible: </b>
                            <span :class="[item.quantity >= 1 ? 'stock-available' : 'stock-low']">
                                <b>{{ item.quantity }} En Stock</b>
                            </span>
                        </span>
                    </div>
                </b-card-text>
                <template #footer>
                    <b class="d-flex align-items-center">
                        <span class="item-price"> ${{numberFormat(item.precio_final_con_imp)}} </span> 
                        <b-icon
                            icon="info-circle-fill" 
                            class="icon-info ml-2" 
                            v-b-tooltip.hover 
                            :title="item.taxText" 
                            :style="cssVars"
                            ></b-icon> 
                        <span class="material-icons-outlined note-add-icon ml-auto add-to-cart" v-if="shouldAddItem(item)" @click.prevent="$emit('add-item', item)"> add_shopping_cart</span> 
                        <span class="material-icons-outlined note-add-icon ml-auto color-block" v-else> block </span> 
                    </b>
                </template>
            </b-card>
            
        </b-card-group>
    </b-row>
</template>

<script>

import { mapState } from 'vuex'
export default {
    name: "CardItem",
    props: {
        items: {
            type: Array
        }       
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('configuration', ['background']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
    },
    methods: {
        shouldAddItem(item){
            if(item.quantity > 0 && item.precio_final_con_imp > 0) {
                return true;
            }
            return false;
        }
    }
};

</script>
<style scoped>
.card-img { 
    max-width: 100%; 
    max-height: 150px;
    object-fit: scale-down;
}

.icon-info{
    color: var(--border-bottom);
    cursor: pointer;
}
.card-footer{
    background-color: white;
    border-top: none
}

.card{
    border-radius: 0px;
}
/* new*/
.card-item-detail{
    font-size: 12px;
}
.card-item-name{
    min-height: 70px !important;
}
.item-name{
    font-size: 15px;
    color: #273c99 !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}
.item-price{
    color: rgb(5, 161, 5) !important;
    font-size: 18px;
}

@media (max-width: 480px) {
    .card-item-name{
        min-height: 40px !important;
    }
}

</style>